import React, {Component} from 'react';

import "./Page.scss";


class Page extends Component {
	render() {
		return (
			<div className={"Page"}>
				<div className={"pageHeader"}>
					<h1>{this.props.title}</h1>
					<p>{this.props.teaser}</p>
				</div>
				{this.props.children}
			</div>
		);
	}
}

export default Page;
