import React, {Component} from 'react';
import TwoByGrid from "./TwoByGrid";

import "./ContactForm.scss";
import * as axios from "axios";


class ContactForm extends Component {
	constructor(props) {
		super(props);

		this.sendForm = this.sendForm.bind(this);
		this.onFieldChanged = this.onFieldChanged.bind(this);

		this.state = {
			mayEdit: true,
			name: "",
			email: "",
			subject: "",
			text: ""
		}
	}

	render() {
		if (this.props.feedback) {
			return (
				<div className={"ContactForm"}>
					<TwoByGrid className={"form"}>
						<div className={"input fullWidth"}>
							<p>Projekt</p>
							<input type={"text"}
								   disabled
								   value={this.props.project}
								   aria-label={"Projekt"}
							/>
						</div>
						<div className={"input text"}>
							<p>Ihre Nachricht</p>
							<textarea rows={11}
									  value={this.state.text}
									  onChange={(e) => this.onFieldChanged(e, "text")}
									  aria-label={"Ihre Nachricht"}
							/>
						</div>
					</TwoByGrid>
					<div className={"sendbuttonSpacer"}/>
					<div className={"sendbutton"} onClick={(e) => this.sendFeedback(true, e)} aria-label={"Absenden"}>Absenden und Veröffentlichung zustimmen</div>
					<div className={"sendbutton mute"} onClick={(e) => this.sendFeedback(false, e)} aria-label={"Absenden"}>Absenden aber Veröffentlichung ablehnen</div>
					<p className={"legalHint"}>
						Mit dem Absenden bestätigen Sie zu, dass Ihre Angaben aus dem Kontaktformular zur Beantwortung
						meiner Anfrage erhoben und verarbeitet werden.
						Bitte kontaktieren Sie uns unter {this.props.mailrecipient} um Ihre Einwilligung zurückzuziehen.
						Für weitere Informationen finden Sie unten auf der Seite einen Link zu unseren
						Datenschutzbedingungen.
					</p>
				</div>
			);
		} else {
			return (
				<div className={"ContactForm"}>
					<TwoByGrid className={"form"}>
						<div className={"input halfWidth"}>
							<p>Ihr Name</p>
							<input type={"text"}
								   value={this.state.name}
								   onChange={(e) => this.onFieldChanged(e, "name")}
								   aria-label={"Ihr Name"}
							/>
						</div>
						<div className={"input halfWidth"}>
							<p>Ihr E-Mail Adresse</p>
							<input type={"text"}
								   value={this.state.email}
								   onChange={(e) => this.onFieldChanged(e, "email")}
								   aria-label={"Ihre E-Mail Adresse"}
							/>
						</div>
						<div className={"input fullWidth"}>
							<p>Betreff</p>
							<input type={"text"}
								   value={this.state.subject}
								   onChange={(e) => this.onFieldChanged(e, "subject")}
								   aria-label={"Betreff"}
							/>
						</div>
						<div className={"input text"}>
							<p>Ihre Nachricht</p>
							<textarea rows={11}
									  value={this.state.text}
									  onChange={(e) => this.onFieldChanged(e, "text")}
									  aria-label={"Ihre Nachricht"}
							/>
						</div>
					</TwoByGrid>
					<div className={"sendbuttonSpacer"}/>
					<div className={"sendbutton"} onClick={this.sendForm} aria-label={"Absenden"}>Absenden</div>
					<p className={"legalHint"}>
						Mit dem Absenden bestätigen Sie zu, dass Ihre Angaben aus dem Kontaktformular zur Beantwortung
						meiner Anfrage erhoben und verarbeitet werden.
						Bitte kontaktieren Sie uns unter {this.props.mailrecipient} um Ihre Einwilligung zurückzuziehen.
						Für weitere Informationen finden Sie unten auf der Seite einen Link zu unseren
						Datenschutzbedingungen.
					</p>
				</div>
			);
		}
	}

	onFieldChanged(e, tarVar) {
		if (this.state.mayEdit) {
			this.setState({[tarVar]: e.target.value})
		}
	}

	sendFeedback(consent, e) {
		if(this.state.mayEdit) {
			if (this.state.text.replaceAll(" ", "") !== "") {
				this.setState({mayEdit: false});
				e.target.innerText = "Gesendet, vielen Dank!";
				e.target.classList += " final";

				axios({
					method: 'put',
					url: 'https://cms.sorglos-bauen.com/feedback-anfrages/'+this.props.id,
					data: {
						ganzesFeedback: this.state.text,
						abgegeben: true,
						veroeffentlichungErlaubt: consent
					}
				})
					.then(() => {
						document.querySelectorAll(".sendbutton")[0].innerText = "Gesendet";
						document.querySelectorAll(".sendbutton")[1].innerText = "vielen Dank!";

						axios.post("//sbprev.schroetlerdev.de/feedback-mail.php", {
							recipient: this.props.mailrecipient,
							subject: "Es wurde Feedback für "+this.props.project+" hinterlassen",
							project: this.props.project,
							feedbackId: this.props.id
						}).then(() => {}).catch(() => {});
					})
					.catch((e) => {
						document.querySelectorAll(".sendbutton")[0].innerText = "Fehler, bitte versuchen Sie es später erneut";
						document.querySelectorAll(".sendbutton")[0].classList += " final";
						document.querySelectorAll(".sendbutton")[1].innerText = "Fehler, bitte versuchen Sie es später erneut";
						document.querySelectorAll(".sendbutton")[1].classList += " final";
					});
			} else {
				e.target.innerText = "Bitte geben eine Nachricht ein";
				setTimeout(() => {
					document.querySelectorAll(".sendbutton")[0].innerText = "Absenden und Veröffentlichung zustimmen";
					document.querySelectorAll(".sendbutton")[1].innerText = "Absenden aber Veröffentlichung ablehnen";
				}, 3000)
			}
		}
	}

	sendForm() {
		if (this.state.mayEdit) {
			if (this.state.name.replaceAll(" ", "") !== "") {
				if (this.state.email.replaceAll(" ", "") !== "" && /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.state.email)) {
					if (this.state.subject.replaceAll(" ", "") !== "") {
						if (this.state.text.replaceAll(" ", "") !== "") {
							axios.post("//sbprev.schroetlerdev.de/kontakt-mail.php", {
								recipient: this.props.mailrecipient,
								name: this.state.name,
								sender: this.state.email,
								subject: this.state.subject,
								message: this.state.text
							}).then(() => {
								this.setState({mayEdit: false});
								document.querySelectorAll(".sendbutton")[0].innerText = "Gesendet, vielen Dank!";
								document.querySelectorAll(".sendbutton")[0].classList += " final";
							}).catch(() => {
								this.setState({mayEdit: false});
								document.querySelectorAll(".sendbutton")[0].innerText = "Fehler, bitte versuchen Sie es später erneut";
								document.querySelectorAll(".sendbutton")[0].classList += " final";
							});
						} else {
							document.querySelectorAll(".sendbutton")[0].innerText = "Bitte geben eine Nachricht ein";
							setTimeout(() => {
								document.querySelectorAll(".sendbutton")[0].innerText = "Absenden";
							}, 3000)
						}
					} else {
						document.querySelectorAll(".sendbutton")[0].innerText = "Bitte geben einen Betreff an";
						setTimeout(() => {
							document.querySelectorAll(".sendbutton")[0].innerText = "Absenden";
						}, 3000)
					}
				} else {
					document.querySelectorAll(".sendbutton")[0].innerText = "Bitte geben Sie Ihre E-Mail Adresse ein";
					setTimeout(() => {
						document.querySelectorAll(".sendbutton")[0].innerText = "Absenden";
					}, 3000)
				}
			} else {
				document.querySelectorAll(".sendbutton")[0].innerText = "Bitte geben Sie Ihren Namen ein";
				setTimeout(() => {
					document.querySelectorAll(".sendbutton")[0].innerText = "Absenden";
				}, 3000)
			}
		}
	}
}

export default ContactForm;
