import React, {Component} from 'react';
import STARTSEITE_QUERY from "../querying/queries/startseite";
import Query from "../querying/Query";

import Hero from "../components/Hero";
import Section from "../components/Section";
import TwoByGrid from "../components/TwoByGrid";
import Card from "../components/Card";
import Spacer from "../components/Spacer";
import Button from "../components/Button";
import CTA from "../components/CTA";
import RealEstateCard from "../components/RealEstateCard";


class Startseite extends Component {
	render() {
		return (
			<div>
				<Query query={STARTSEITE_QUERY}>
					{(data) => {
						document.getElementById("pageDesc").setAttribute("content", data.data.footer.beschreibung);

						return (
							<div className={"SpecialPage Startseite"}>
								<Hero data={data.data.startseite}/>
								<Section title={data.data.startseite.dienstleistungen_titel} teaser={data.data.startseite.dienstleistungen_teaser}>
									<TwoByGrid>
										{data.data.startseite.dienstleistungen.map((dienstleistung) => {
											return (<Card key={dienstleistung.id} title={dienstleistung.titel} content={dienstleistung.inhalt} />)
										})}
									</TwoByGrid>
									<Spacer height={"150px"} />
									<Button text={data.data.startseite.dienstleistungen_button_text} target={"/ueber-uns"} style={{marginBottom: "100px"}} isAtEndOfSection />
								</Section>
								<CTA />
								<Section title={data.data.startseite.immobilien_titel} teaser={data.data.startseite.immobilien_teaser}>
									<TwoByGrid wide>
										{data.data.immobilies.sort((a, b) => {
											return b.sortierungsIndex - a.sortierungsIndex;
										}).map((immo) => {
											// eslint-disable-next-line react/jsx-no-undef
											return (<RealEstateCard key={immo.id} data={immo} />)
										})}
									</TwoByGrid>
									<Spacer height={"92px"} />
									<Button text={data.data.startseite.immobilien_button_text} target={"/immobilien"} style={{marginBottom: "100px"}} isAtEndOfSection />
								</Section>
							</div>
						);
					}}
				</Query>
			</div>
		);
	}
}

export default Startseite;
