import React, {Component} from 'react';

import "./TwoByGrid.scss";


class TwoByGrid extends Component {
	render() {
		if (this.props.wide) {
			return (
				<div className={"TwoByGrid wide"}>
					{this.props.children}
				</div>
			);
		}
		else if (this.props.small) {
			return (
				<div className={"TwoByGrid small"}>
					{this.props.children}
				</div>
			);
		}
		else {
			return (
				<div className={"TwoByGrid"}>
					{this.props.children}
				</div>
			);
		}
	}
}

export default TwoByGrid;
