import React, {Component} from 'react';
import Query from "../querying/Query";
import LEISTUNGEN_QUERY from "../querying/queries/leistungen";
import Page from "../components/Page";
import Spacer from "../components/Spacer";
import CTA from "../components/CTA";
import Card from "../components/Card";
import ReactMarkdown from "react-markdown";
import TwoByGrid from "../components/TwoByGrid";

class Leistungen extends Component {
	render() {
		return (
			<div>
				<Query query={LEISTUNGEN_QUERY}>
					{(data) => {
						document.getElementById("pageDesc").setAttribute("content", data.data.warumSorglosBauen.teaser);

						return (
							<Page title={data.data.warumSorglosBauen.titel} teaser={data.data.warumSorglosBauen.teaser}>
								<TwoByGrid>
									{data.data.warumSorglosBauen.Leistungen.map(leistung => {
										return (
											<Card title={leistung.Titel} content={<ReactMarkdown source={leistung.Inhalt} escapeHtml={false} />} key={leistung.id} style={{gridColumn: "1 / span 2"}} />
										)
									})}
								</TwoByGrid>
								<Spacer height={"150px"} />
								<CTA />
							</Page>
						)
					}}
				</Query>
			</div>
		);
	}
}

export default Leistungen;
