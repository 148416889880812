import React, {Component} from 'react';
import Query from "../querying/Query";
import UEBER_UNS_QUERY from "../querying/queries/ueber-uns";
import Page from "../components/Page";
import TwoByGrid from "../components/TwoByGrid";
import ContactCard from "../components/ContactCard";
import Spacer from "../components/Spacer";

class UeberUns extends Component {
	render() {
		return (
			<div>
				<Query query={UEBER_UNS_QUERY}>
					{(data) => {
						document.getElementById("pageDesc").setAttribute("content", data.data.ueberUn.teaser);

						return (
							<Page title={data.data.ueberUn.titel} teaser={data.data.ueberUn.teaser}>
								<TwoByGrid>
									{data.data.ueberUn.Kontaktperson.map((kontaktperson) => {
										return (<ContactCard key={kontaktperson.id} data={kontaktperson}/>);
									})}
								</TwoByGrid>
								<Spacer height={"150px"} />
							</Page>
						)
					}}
				</Query>
			</div>
		);
	}
}

export default UeberUns;
