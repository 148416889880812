import React, {Component} from 'react';

import "./Button.scss";


class Button extends Component {
	render() {
		return (
			<div className={"Button"} style={this.props.isAtEndOfSection?{marginBottom: "90px"}:{}} onClick={() => {
				window.location.href = this.props.target
			}}>
				{this.props.text}
			</div>
		);
	}
}

export default Button;
