import React, {Component} from 'react';

import "./Hero.scss";

class Hero extends Component {
	timer;

	constructor(props) {
		super(props);

		this.state = {
			currentImage: 1,
			imageCount: this.props.data.hintergrund.length,
			skipOne: false
		}
	}

	componentDidMount() {
		this.timer = setInterval(() => {
			if(!this.state.skipOne) {
				this.carouselFW({}, true);
			}
			else {
				this.setState({skipOne: false});
			}
		}, 4000);
	}

	componentWillUnmount() {
		clearInterval(this.timer);
	}


	render() {
		return (
			<div className={"Hero"}>
				<div className={"textContainer"}>
					<h1>{this.props.data.titel}</h1>
					<p>{this.props.data.teaser}</p>
				</div>
				<div className={"background"}>
					<div className={"cover"}/>
					{this.props.data.hintergrund.map((hintergrund, index) => {
						return (
							<div
								key={hintergrund.name}
								className={((this.state.currentImage - 1) === index)?"backgroundImage shown":"backgroundImage hidden"}
								style={{
									backgroundImage: `url("//cms.sorglos-bauen.com${hintergrund.url}")`
								}}
							/>
						);
					})}
				</div>
				{
					(this.state.imageCount !== 1)?(<div className={"carouselControl"}>
						<div className={"arrLeft"} onClick={this.carouselBW.bind(this)}>&lt;</div>
						<div className={"indicator"}>
							<p>{this.state.currentImage} | {this.state.imageCount}</p>
						</div>
						<div className={"arrRight"} onClick={this.carouselFW.bind(this)}>&gt;</div>
					</div>):null
				}
			</div>
		);
	}

	carouselFW(e, automated = false) {
		if(!automated) {
			this.setState({skipOne: true});
		}

		if(this.state.currentImage === this.state.imageCount) {
			this.setState({currentImage: 1});
		}
		else {
			this.setState({currentImage: this.state.currentImage + 1});
		}
	}

	carouselBW() {
		if(this.state.currentImage === 1) {
			this.setState({currentImage: this.state.imageCount});
		}
		else {
			this.setState({currentImage: this.state.currentImage - 1});
		}
	}
}

export default Hero;
