import React, {Component} from 'react';

import "./Title.scss";


class Title extends Component {
	render() {
		if(this.props.isInPageHeader) {
			return (
				<div className={"Title"}>
					<h1>{this.props.title}</h1>
					<p>{this.props.teaser}</p>
				</div>
			);
		}
		else {
			return (
				<div className={"Title"}>
					<h2>{this.props.title}</h2>
					<p>{this.props.teaser}</p>
				</div>
			)
		}
	}
}

export default Title;
