import gql from "graphql-tag";

const NAVBAR_QUERY = gql`
	query Navbar {
		navigation {
			logo {
				url
			}, 
			link {
        		bezeichnung,
        		ziel,
        		hervorgehoben,
        		angezeigt
			}
		}
	}
`;

export default NAVBAR_QUERY;
