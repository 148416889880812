import React, {Component} from 'react';

import "./RealEstateHero.scss";
import Spacer from "./Spacer";
import Button from "./Button";

class RealEstateHero extends Component {
	render() {
		console.log(this.props.data);
		return (
			<div className={"RealEstateHero"}>
				<div className={"background"}>
					<div className={"cover"}/>
						<div
							className={"backgroundImage"}
							style={{
								backgroundImage: `url("//cms.sorglos-bauen.com${this.props.data.vorschaubild.url}")`
							}}
						/>
				</div>
				<div className={"textContainer"}>
					<div>
						<h1>{this.props.data.titel}</h1>
							<div className={"buttonRow"}>
								{this.props.data.listings.map((listing) => {
									return (<Button key={listing.id} text={listing.titel} target={listing.url} />);
								})}
							</div>
						<Spacer height={"15px"} />
					</div>
				</div>
			</div>
		);
	}
}

export default RealEstateHero;
