import React from "react";

import { Switch, Route } from "react-router-dom";
import gql from "graphql-tag";
import SimpleReactLightbox from "simple-react-lightbox";

import Navbar from "./components/Navbar";
import Query from "./querying/Query";
import Footer from "./components/Footer";
import Startseite from "./pages/Startseite";
import UeberUns from "./pages/UeberUns";
import Leistungen from "./pages/Leistungen";
import Immobilien from "./pages/Immobilien";
import Rechtliches from "./pages/Rechtliches";
import Kontakt from "./pages/Kontakt";
import Immobilie from "./pages/Immobilie";
import Feedback from "./pages/Feedback";

function App() {
	let path = window.location.pathname.split("/");
	let res = "";

	let add = path[path.length - 1].split("-").join(" ").toLowerCase();
	add = add.replace(/ae/g, "ä");
	add = add.replace(/oe/g, "ö");
	add = add.replace(/ue/g, "ü");
	add = add.charAt(0).toUpperCase() + add.substr(1, add.length - 1);
	if(add !== "") {
		add += " | ";
	}
	res += add;

	res += "sorglos bauen GmbH"

	document.getElementById("title").innerText = res;



	return (
		<SimpleReactLightbox>
			<div className="App">
				<Navbar />
				<Switch>
					<Route path="/" component={Startseite} exact />
					<Route path="/ueber-uns" component={UeberUns} exact />
					<Route path="/leistungen" component={Leistungen} exact />
					<Route path="/immobilien" component={Immobilien} exact />
					<Route path="/immobilie/:id" component={Immobilie} exact />
					<Route path="/kontakt" component={Kontakt} exact />
					<Route path="/feedback/:id" component={Feedback} exact />
					<Route path="/rechtliches/:url" component={Rechtliches} exact />
					<Route path="*" component={() => {return (<Query query={gql`query Error {erroroutonpurpose {title}}`} />)}} />
				</Switch>
				<Footer />
			</div>
		</SimpleReactLightbox>
	);
}

export default App;
