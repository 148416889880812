import React from "react";
import { useQuery } from "@apollo/react-hooks";
import {BounceLoader} from "react-spinners";

import "./Query.scss";

const Query = (props) => {
  const { data, loading, error } = useQuery(props.query, {variables: props.variables});

  if (loading) {
    return (
        <div className={"Query loading"}>
          <BounceLoader
              size={100}
              color={"#F20D0D"}
          />
        </div>
      );
  }

  if (error) {
    console.log(error);

    return (
        <div className={"Query error"}>
            <div className={"content"}>
                <h1>Fehler</h1>
                <p>
                    Beim Laden dieser Seite ist ein Fehler aufgetreten.<br />
                    Bitte versuchen Sie es später erneut.
                </p>
            </div>
        </div>
    );
  }

  return props.children({ data });
};

export default Query;
