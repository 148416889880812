import gql from "graphql-tag";

const LEISTUNGEN_QUERY = gql`
	query Leistungen {
		warumSorglosBauen {
			titel,
			teaser,
			Leistungen {
				id,
				Titel,
				Inhalt
			}
		}	
	}
`;

export default LEISTUNGEN_QUERY;
