import React, {Component} from 'react';
import Query from "../querying/Query";
import Page from "../components/Page";
import Spacer from "../components/Spacer";
import KONTAKT_QUERY from "../querying/queries/kontakt";
import ContactForm from "../components/ContactForm";

class Kontakt extends Component {


	render() {
		return (
			<div>
				<Query query={KONTAKT_QUERY}>
					{(data) => {
						document.getElementById("pageDesc").setAttribute("content", data.data.kontakt.teaser);

						return (
							<Page title={data.data.kontakt.titel} teaser={data.data.kontakt.teaser}>
								<ContactForm mailrecipient={data.data.kontakt.empfaenger}/>
								<Spacer height={"150px"} />
							</Page>
						)
					}}
				</Query>
			</div>
		);
	}
}

export default Kontakt;
