import React, {Component} from 'react';
import "./MetaOverviewCard.scss";
import {DashboardOutlined, ListAltOutlined, LocalOfferOutlined, TodayOutlined} from "@material-ui/icons";

class MetaOverviewCard extends Component {
	render() {
		return (
			<div className={"MetaOverviewCard"}><div className={"entry"}>
				<ListAltOutlined />
				<p>{this.props.data.fertiggestellt?"Fertiggestellt":"Im Bau"}</p>
			</div>
				<div className={"divider"} />
				<div className={"entry"}>
					<LocalOfferOutlined />
					<p>{
						this.props.data.kategorie
							.replaceAll("ae", "ä")
							.replaceAll("oe", "ö")
							.replaceAll("ue", "ü")
					}</p>
				</div>
				<div className={"divider"} />
				<div className={"entry"}>
					<DashboardOutlined />
					<p>{this.props.data.groesseJeEinheit}</p>
				</div>
				<div className={"divider"} />
				<div className={"entry"}>
					<TodayOutlined />
					<p>{(new Date(this.props.data.fertigstellungsdatum).getMonth() + 1 < 10)?"0":null}{new Date(this.props.data.fertigstellungsdatum).getMonth() + 1} / {new Date(this.props.data.fertigstellungsdatum).getFullYear().toString().substr(2,2)}</p>
				</div>
			</div>
		);
	}
}

export default MetaOverviewCard;
