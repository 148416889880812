import gql from "graphql-tag";

const STARTSEITE_QUERY = gql`
	query Startseite {
		startseite {
			titel,
			teaser,
			hintergrund {
				url,
				formats,
				name,
				id
			},
			dienstleistungen_titel,
			dienstleistungen_teaser,
			dienstleistungen {
				id,
				titel,
				inhalt
			},
			dienstleistungen_button_text,
			immobilien_titel,
			immobilien_teaser,
			immobilien_button_text
		},
		immobilies(where: {fertiggestellt: false}) {
			id, 
			url,
			titel,
			vorschaubild {
				formats
			},
			fertiggestellt,
			kategorie,
			groesseJeEinheit,
			fertigstellungsdatum,
			sortierungsIndex
		},
		footer {
			beschreibung
		}
	}
`;

export default STARTSEITE_QUERY;
