import React, {Component} from 'react';
import Query from "../querying/Query";
import IMMOBILIEN_QUERY from "../querying/queries/immobilien";
import Page from "../components/Page";
import TwoByGrid from "../components/TwoByGrid";
import Spacer from "../components/Spacer";
import RealEstateCard from "../components/RealEstateCard";
import {FilterListOutlined} from "@material-ui/icons"

import "./Immobilien.scss";
import Popover from "react-popover";


export const FILTER_STATES = {
	ALLE: "all",
	EINFAMILIENHAUS: "Einfamilienhaus",
	DOPPELHAUSHAELFTE: "Doppelhaushaelfte",
	MEHRFAMILIENHAUS: "Mehrfamilienhaus",
	KETTENHAUS: "Kettenhaus",
	BUNGALOW: "Bungalow"
};


class Immobilien extends Component {

	constructor(props) {
		super(props);

		this.state = {
			currentFilter: "all",
			popoverOpen: false
		}

		this.setFilter = this.setFilter.bind(this);
	}

	render() {
		return (
			<div>
				<Query query={IMMOBILIEN_QUERY}>
					{(data) => {
						document.getElementById("pageDesc").setAttribute("content", data.data.immobilien.teaser);

						return (
							<Page title={data.data.immobilien.titel} teaser={data.data.immobilien.teaser}>
								<div>
									<Popover
										isOpen={this.state.popoverOpen}
										body={
											(
												<ul className={"categoryFilters"}>
													<li
														onClick={() => {this.setFilter(FILTER_STATES.ALLE)}}
														className={(this.state.currentFilter === FILTER_STATES.ALLE)?"active":"inactive"}
													>
														Alle
													</li>

													<li
														onClick={() => {this.setFilter(FILTER_STATES.EINFAMILIENHAUS)}}
														className={(this.state.currentFilter === FILTER_STATES.EINFAMILIENHAUS)?"active":"inactive"}
													>
														Einfamilienhaus
													</li>

													<li
														onClick={() => {this.setFilter(FILTER_STATES.DOPPELHAUSHAELFTE)}}
														className={(this.state.currentFilter === FILTER_STATES.DOPPELHAUSHAELFTE)?"active":"inactive"}
													>
														Doppelhaushälfte
													</li>

													<li
														onClick={() => {this.setFilter(FILTER_STATES.MEHRFAMILIENHAUS)}}
														className={(this.state.currentFilter === FILTER_STATES.MEHRFAMILIENHAUS)?"active":"inactive"}
													>
														Mehrfamilienhaus
													</li>

													<li
														onClick={() => {this.setFilter(FILTER_STATES.KETTENHAUS)}}
														className={(this.state.currentFilter === FILTER_STATES.KETTENHAUS)?"active":"inactive"}
													>
														Kettenhaus
													</li>

													<li
														onClick={() => {this.setFilter(FILTER_STATES.BUNGALOW)}}
														className={(this.state.currentFilter === FILTER_STATES.BUNGALOW)?"active":"inactive"}
													>
														Bungalow
													</li>
												</ul>
											)
										}
										preferPlace={"below"}
										appendTarget={document.querySelectorAll(".Page")[0]}
									>
										<div className={"filterButton"} onClick={() => {this.setState({popoverOpen: !this.state.popoverOpen})}}><FilterListOutlined  /><br /><p>Filtern</p></div>
									</Popover>
								</div>

								<TwoByGrid wide>
									{data.data.immobilies.filter((immo) => {
										if (this.state.currentFilter === FILTER_STATES.ALLE) {
											return true;
										}
										else {
											return immo.kategorie === this.state.currentFilter;
										}
									}).sort((a, b) => {
										return b.sortierungsIndex - a.sortierungsIndex;
									}).map((immo) => {
										// eslint-disable-next-line react/jsx-no-undef
										return (<RealEstateCard key={immo.id} data={immo} />)
									})}
								</TwoByGrid>
								<Spacer height={"150px"} />
							</Page>
						)
					}}
				</Query>
			</div>
		);
	}

	setFilter(target) {
		this.setState({
			currentFilter: target,
			popoverOpen: false
		});
		try {
			this.props.onFilterChanged(this.state.currentFilter);
		} catch (e) {}
	}
}

export default Immobilien;
