import React, {Component} from 'react';
import Icon from "@material-ui/core/Icon";

import "./IconButtonRow.scss";


class IconButtonRow extends Component {
	render() {
		return (
			<div className={"IconButtonRow"}>
				{this.props.data.map((iconButton) => {
					if(iconButton.angezeigt) {
						return (
							<div key={iconButton.id} className={"iconButton"}>
								<Icon className={"icon"} onClick={() => {window.location.href = iconButton.ziel}}>{iconButton.icon_bezeichnung}</Icon>
							</div>
						)
					}
					return null;
				})}
			</div>
		);
	}
}

export default IconButtonRow;
