import React, {Component} from 'react';
import CTA_QUERY from "../querying/queries/cta";
import Query from "../querying/Query";

import "./CTA.scss";
import * as axios from "axios";


class CTA extends Component {
	mailrecipient = "";

	constructor(props) {
		super(props);

		this.state = {
			phoneno: "",
			mailrecipient: ""
		}

		this.phonenoupdated = this.phonenoupdated.bind(this);
		this.phonenosend = this.phonenosend.bind(this);
	}

	render() {
		return (
			<div>
				<Query query={CTA_QUERY}>
					{(data) => {
						this.mailrecipient = data.data.cta.empfaenger;

						return (
							<div className={"CTA"}>
								<div className={"links"}>
									<h2>{data.data.cta.titel}</h2>
									<p>{data.data.cta.teaser}</p>

									<div className={"contactOptions"}>
										<div className={"option"}>
											<p>Rückruf anfordern</p>
											<input type={"text"} placeholder={"Ihre Telefonnummer"} value={this.state.phoneno} onChange={this.phonenoupdated} aria-label={"Fordern Sie einen Rückruf an, indem Sie hier Ihre Telefonnummer eintragen"}/>
											<div className={"sendbutton"} onClick={this.phonenosend} aria-label={"Absenden"}>
												>
											</div>
										</div>
										<div className={"option"}>
											<p>oder senden Sie uns eine Nachricht</p>
											<div className={"button"} onClick={() => {
												window.location.href = "/kontakt"
											}}>
												Kontakt
											</div>
										</div>
									</div>
								</div>
								<div className={"rechts"}>
									<div style={{backgroundImage: `url('//cms.sorglos-bauen.com${data.data.cta.bild.formats.large.url}')`}} />
								</div>
							</div>
						);
					}}
				</Query>
			</div>
		);
	}

	phonenoupdated(e) {
		this.setState({phoneno: e.target.value});
	}

	phonenosend() {
		if(this.state.phoneno.length > 3) {
			document.querySelectorAll(".sendbutton")[0].onClick = () => {};
			document.querySelectorAll(".sendbutton")[0].innerText = "";
			document.querySelectorAll(".sendbutton")[0].classList += " send";
			setTimeout(function() {
				document.querySelectorAll(".sendbutton")[0].innerText = "Wird gesendet...";
				// ToDo: Switch to non-preview domain
				axios.post("//sbprev.schroetlerdev.de/rueckruf-mail.php", {
					recipient: this.mailrecipient,
					pageTitle: window.location.href,
					phoneno: this.state.phoneno
				}).then(() => {
					document.querySelectorAll(".sendbutton")[0].innerText = "Gesendet, vielen Dank!";
				}).catch(() => {
					document.querySelectorAll(".sendbutton")[0].innerText = "Fehler, bitte versuchen Sie es später erneut";
				});
			}.bind(this), 500);
		}
		else {
			alert("Bitte geben Sie Ihre Telefonnummer ein");
		}
	}
}

export default CTA;
