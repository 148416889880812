import gql from "graphql-tag";

function RECHTLICHES_QUERY(url) {
	return (gql`
		query Rechtliches {
			rechtliches(where: {urlErweiterung: "${url}"}) {
				titel,
				teaser,
				inhalt
			}	
		}
	`);
}

export default RECHTLICHES_QUERY;
