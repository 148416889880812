import React, {Component} from 'react';
import ReactMarkdown from "react-markdown";
import "./ProgressReportCard.scss";

class ProgressReportCard extends Component {
	render() {
		let bericht = this.props.data;
		return (
			<div className={"ProgressReport"}>
				<p className={"date"}>{ this.reformatDate(bericht.datum) }</p>
				<h3>{ bericht.titel }</h3>
				<ReactMarkdown source={bericht.inhalt} escapeHtml={false} />
				{
					((bericht.bilder != null)?(
						<div className={"reportImages"}>
							{
								bericht.bilder.map(bild => {
									return (<img className={"reportImage"} src={'//cms.sorglos-bauen.com'+bild.url} alt={bild.caption} />);
								})
							}
						</div>
					):null)
				}
			</div>
		);
	}

	reformatDate(date) {
		return new Date(date).toLocaleDateString('de-DE', { day: 'numeric', month: 'numeric', year: 'numeric' })
	}
}

export default ProgressReportCard;
