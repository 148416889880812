import gql from "graphql-tag";

const UEBER_UNS_QUERY = gql`
	query UeberUns {
		ueberUn {
			titel,
			teaser,
			Kontaktperson {
				id, 
				bild {
					formats
				},
				name,
				position,
				info,
				telefonnummer,
				email,
				hervorgehoben
			}
		}	
	}
`;

export default UEBER_UNS_QUERY;
